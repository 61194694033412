import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RecurringProjectStatuses } from '@poly/constants';
import { DatePicker, PROJECT_STATUS_COLORS } from '@poly/site-ui';
import {
  FilterByStatus,
  IconButton,
  HeadingH3,
  HeadingH5,
  Heading,
  Button,
  Editor,
  Holder,
  Modal,
  Input,
  Radio,
  Grid,
  Icon,
  Pip,
  S,
} from '@poly/site-book';

import Select from '../select/index.js';
import ButtonLoader from '../loader/button.js';
import { Rows } from '../../util/forms/index.js';
import ServerError from '../server-error/index.js';
import { ValidationMessage } from '../../styled.js';
import { REPEATS } from '../../constants/report/master-recurring.js';
import CreateResponseTeam from '../../containers/project/create-response-team.js';
import { generateRange } from '../../util/project-scheduler-utils.js';
import {
  formatSearchPlaceholder,
  formatPlaceholder,
} from '../../util/select.js';

const MAX_WIDTH = 320;

const Header = Modal.Item.withComponent(Heading);

const Form = Modal.Item.withComponent('form');

const Table = Modal.Item.withComponent(Grid);

const ScheduleSelectS = styled(Select)`
  min-width: unset;
`;

const Content = styled(Modal.Item)`
  display: grid;
  grid-gap: 50px;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(2, 450px);

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Scroll = styled.section`
  width: 100%;
  height: 100%;
  max-height: 580px;
  display: flex;
  flex-direction: column;
  padding-right: 5px;
  overflow-y: auto;
`;

const Container = styled.section`
  height: 100%;
  display: grid;
  grid-row-gap: 25px;
  grid-template-rows: auto 1fr;
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const HiddenInput = styled(Input)`
  visibility: hidden;
`;

function SimpleTitle({ label }) {
  return (
    <S type="content" title={label}>
      {label}
    </S>
  );
}

SimpleTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

const mapper = (value) => ({
  value: R.prop('_id', value),
  label: R.prop('name', value),
});

const statuses = [
  RecurringProjectStatuses.ACTIVE,
  RecurringProjectStatuses.CLOSED,
];

const statusColors = {
  [RecurringProjectStatuses.ACTIVE]: PROJECT_STATUS_COLORS.active,
  [RecurringProjectStatuses.CLOSED]: PROJECT_STATUS_COLORS.closed,
};

function ColoredTitle({ label }) {
  return (
    <Pip color={statusColors[label]}>
      <S type="title">{label}</S>
    </Pip>
  );
}

ColoredTitle.propTypes = {
  label: PropTypes.string.isRequired,
};

function AddMasterWorkOrder({
  isProjectAssigned,
  setRef,
  loading,
  isNever,
  closeModal,
  propertyLoading,

  client,
  repeatNum,
  repeatTime,
  startDate,
  endDate,
  selectedProperty,
  selectedService,
  invoiceDescription,
  description,
  location,

  repeats,
  repeatEvery,
  serviceTypes,
  properties,
  onSubmit,
  setActiveTab,
  activeTab,
  selectedStatus,

  managers,
  technicians,
  allSuppliers,

  setTechnician,
  setManager,
  setSuppliers,

  projectTechnician,
  projectManager,
  projectSuppliers,

  handleTechniciansClick,
  handleManagersClick,
  handleSuppliersClick,
  onChange,
  setFilterByStatus,
  selectStatus,

  errors,
  setSearch,
  onInputChange,

  selectProperty,
  selectService,
  setPropertySearch,
  selectRepeatNum,
  selectRepeatTime,
  setStartDate,
  setEndDate,
  setNever,
  setRepeatEvery,
  serviceLoading,
  setServiceSearch,
  noPropertyResults,
  title,
  isEdit,
  submitBtnCaption,
}) {
  return (
    <Modal show>
      <Header margin={40}>
        <HeadingH3 lighter>{title}</HeadingH3>
        <IconButton onClick={closeModal}>
          <Icon name="close" fill="#888b97" dimensions={{ width: 10 }} />
        </IconButton>
      </Header>
      <Form {...{ onSubmit }}>
        <Content margin={40}>
          <section>
            <Modal.Item margin={20}>
              <HeadingH5>WO Information</HeadingH5>
            </Modal.Item>
            <Table
              columns={`120px minmax(200px, ${MAX_WIDTH}px)`}
              margin={30}
              simple
            >
              <Rows
                items={[
                  ...(isEdit
                    ? [
                        {
                          title: {
                            value: 'Status',
                            key: 'status',
                          },
                          item: (
                            <Select
                              value={selectedStatus}
                              onChange={selectStatus}
                              placeholder={formatPlaceholder('Status')}
                              options={statuses.map((status) => ({
                                value: status,
                                label: status,
                              }))}
                              optionRenderer={ColoredTitle}
                              clearable={false}
                            />
                          ),
                        },
                      ]
                    : []),
                  {
                    title: {
                      value: 'Service',
                      props: ['required'],
                      key: 'service',
                    },
                    error: R.prop('service', errors),
                    item: (
                      <Select
                        value={selectedService}
                        onChange={selectService}
                        onInputChange={setServiceSearch}
                        isLoading={serviceLoading}
                        placeholder={formatPlaceholder('Service Type')}
                        options={R.map(mapper, serviceTypes)}
                        optionRenderer={SimpleTitle}
                        invalid={!!R.prop('service', errors)}
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Description',
                      props: ['required'],
                      key: 'description',
                    },
                    error: R.prop('description', errors),
                    item: (
                      <Input
                        name="description"
                        placeholder="Description"
                        invalid={!!R.prop('description', errors)}
                        defaultValue={description}
                        {...{ onChange }}
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Details',
                      props: ['', 'description'],
                      key: 'details',
                    },
                    item: (
                      <Editor
                        defaultValue={invoiceDescription}
                        registerRef={setRef}
                        placeholder="Details"
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Client',
                      props: ['required'],
                      key: 'client',
                    },
                    error: R.prop('client', errors),
                    item: (
                      <Input
                        name="client"
                        defaultValue={R.prop('name', client)}
                        disabled
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Location',
                      key: 'location',
                    },
                    item: (
                      <Input
                        defaultValue={location}
                        name="location"
                        placeholder="Enter location information"
                      />
                    ),
                  },
                  {
                    title: {
                      value: 'Property',
                      props: ['required'],
                      key: 'property',
                    },
                    error: R.prop('property', errors),
                    item: (
                      <Select
                        value={selectedProperty}
                        onChange={selectProperty}
                        onInputChange={setPropertySearch}
                        isLoading={propertyLoading}
                        placeholder={formatSearchPlaceholder('Property')}
                        noResultsText={noPropertyResults}
                        options={R.map(mapper, properties)}
                        optionRenderer={SimpleTitle}
                        invalid={!!R.prop('property', errors)}
                      />
                    ),
                  },
                ]}
              />
            </Table>
            <Modal.Item margin={20}>
              <HeadingH5>Planing</HeadingH5>
            </Modal.Item>
            <Table
              columns={`120px minmax(200px, ${MAX_WIDTH}px)`}
              margin={30}
              simple
            >
              <Rows
                items={[
                  {
                    title: {
                      value: 'Repeat every',
                      key: 'repeat-every-1',
                    },
                    item: (
                      <Holder margin={15}>
                        <ScheduleSelectS
                          value={repeatNum}
                          onChange={selectRepeatNum}
                          options={generateRange({ from: 1, to: 30, step: 1 })}
                          optionRenderer={SimpleTitle}
                          clearable={false}
                          disabled={isEdit}
                        />
                        <ScheduleSelectS
                          value={repeatTime}
                          onChange={selectRepeatTime}
                          options={REPEATS.map((repeat) => ({
                            value: repeat,
                            label: repeat,
                          }))}
                          optionRenderer={SimpleTitle}
                          clearable={false}
                          disabled={isEdit}
                        />
                      </Holder>
                    ),
                  },
                  {
                    title: {
                      value: 'Starts on',
                      key: 'starts-on',
                    },
                    item: (
                      <Holder margin={15}>
                        <ItemWrapper>
                          <DatePicker
                            dateFormat="MM-dd-yyyy"
                            onChange={setStartDate}
                            selected={startDate}
                            placeholderText="Starts On"
                            disabled={isEdit}
                          />
                        </ItemWrapper>
                        <ItemWrapper>
                          <HiddenInput />
                        </ItemWrapper>
                      </Holder>
                    ),
                  },
                  {
                    title: {
                      value: 'Ends on',
                      key: 'ends-on',
                    },
                    error: R.prop('endDate', errors),
                    item: (
                      <Holder margin={15}>
                        <ItemWrapper>
                          <DatePicker
                            dateFormat="MM-dd-yyyy"
                            onChange={setEndDate}
                            selected={endDate}
                            minDate={startDate}
                            placeholderText="Ends On"
                            disabled={isNever}
                            invalid={!!R.prop('endDate', errors)}
                          />
                        </ItemWrapper>
                        <ItemWrapper>
                          <Radio
                            id="never"
                            content="Never"
                            type="checkbox"
                            onChange={setNever}
                            checked={isNever}
                          />
                        </ItemWrapper>
                      </Holder>
                    ),
                  },
                  ...(R.isEmpty(repeats)
                    ? []
                    : [
                        {
                          title: {
                            value: 'Repeat every',
                            key: 'repeat-every-2',
                          },
                          item: (
                            <Select
                              value={repeatEvery}
                              onChange={setRepeatEvery}
                              options={repeats}
                              optionRenderer={SimpleTitle}
                              clearable={false}
                              disabled={isEdit}
                            />
                          ),
                        },
                      ]),
                ]}
              />
            </Table>
          </section>
          <Container>
            <section>
              <Modal.Item margin={20}>
                <HeadingH5>Response Team</HeadingH5>
              </Modal.Item>
              <Modal.Item>
                <Holder margin={10}>
                  <FilterByStatus
                    type="button"
                    color="#93acfe"
                    active={!isProjectAssigned}
                    onClick={(event) => {
                      event.preventDefault();
                      setFilterByStatus('queue');
                      setSearch('');
                      setActiveTab({ target: { value: 'technicians' } });
                    }}
                  >
                    <Pip color="#93acfe" />
                    <S type="title">Add to Queue</S>
                  </FilterByStatus>
                  <FilterByStatus
                    type="button"
                    color="#9bdc53"
                    active={isProjectAssigned}
                    onClick={(event) => {
                      event.preventDefault();
                      setFilterByStatus('assign');
                    }}
                  >
                    <Pip color="#9bdc53" />
                    <S type="title">Assign Work Order</S>
                  </FilterByStatus>
                </Holder>
              </Modal.Item>
              {isProjectAssigned && (
                <>
                  <Modal.Item margin={25}>
                    <Input
                      name="search-by-name"
                      placeholder="Start Searching by Name"
                      onChange={onInputChange}
                    />
                  </Modal.Item>
                  <Modal.Item margin={30}>
                    <Holder margin={30}>
                      <S type="badge" uppercase>
                        Select:
                      </S>
                      <Holder margin={40}>
                        <Radio
                          name="members"
                          value="technicians"
                          id="technicians"
                          content={<S type="title">Technicians</S>}
                          onChange={setActiveTab}
                          defaultChecked
                        />
                        <Radio
                          name="members"
                          value="manager"
                          id="manager"
                          content={<S type="title">Manager</S>}
                          onChange={setActiveTab}
                        />
                        <Radio
                          name="members"
                          value="suppliers"
                          id="supplier"
                          content={<S type="title">Supplier</S>}
                          onChange={setActiveTab}
                        />
                      </Holder>
                    </Holder>
                  </Modal.Item>
                </>
              )}
            </section>
            {isProjectAssigned ? (
              <Wrapper>
                <Scroll>
                  <CreateResponseTeam
                    {...{
                      activeTab,

                      allTechnicians: technicians,
                      allSuppliers,
                      managers,

                      setTechnician,
                      setManager,
                      setSuppliers,
                      handleTechniciansClick,
                      handleManagersClick,
                      handleSuppliersClick,

                      projectTechnician,
                      projectManager,
                      projectSuppliers,
                    }}
                  />
                </Scroll>
              </Wrapper>
            ) : (
              <S type="content">This Work Order will be Added to Queue</S>
            )}
            <ValidationMessage error={R.prop('manager', errors)} />
          </Container>
        </Content>
        <ServerError
          error={R.prop('server', errors)}
          style={{ maxWidth: 950 }}
        />
        <Modal.Buttons>
          <Button
            type="reset"
            mode="gray"
            onClick={closeModal}
            disabled={loading}
          >
            <S type="title">Cancel</S>
          </Button>
          <Button type="submit" mode="orange" disabled={loading}>
            {loading && <ButtonLoader />}
            <S type="title">{submitBtnCaption}</S>
          </Button>
        </Modal.Buttons>
      </Form>
    </Modal>
  );
}

AddMasterWorkOrder.propTypes = {
  client: PropTypes.shape({ name: PropTypes.string.isRequired }),
  isNever: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  propertyLoading: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  repeatNum: PropTypes.number.isRequired,
  repeatTime: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  selectedProperty: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  selectedService: PropTypes.string,
  selectProperty: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  selectRepeatNum: PropTypes.func.isRequired,
  selectRepeatTime: PropTypes.func.isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  setNever: PropTypes.func.isRequired,
  setRepeatEvery: PropTypes.func.isRequired,
  // eslint-disable-next-line
  repeatEvery: PropTypes.any,
  repeats: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
  invoiceDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.string,
  location: PropTypes.string,
  serviceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  managers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
  ),
  technicians: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
    }),
  ),
  allSuppliers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
  activeTab: PropTypes.string.isRequired,
  setTechnician: PropTypes.func.isRequired,
  setManager: PropTypes.func.isRequired,
  setSuppliers: PropTypes.func.isRequired,
  projectTechnician: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  projectManager: PropTypes.shape({
    _id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  projectSuppliers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
  ).isRequired,
  selectedStatus: PropTypes.string,
  handleTechniciansClick: PropTypes.func.isRequired,
  handleManagersClick: PropTypes.func.isRequired,
  handleSuppliersClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setFilterByStatus: PropTypes.func.isRequired,
  isProjectAssigned: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    server: PropTypes.string,
    property: PropTypes.string,
    description: PropTypes.string,
  }),
  setRef: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  setPropertySearch: PropTypes.func.isRequired,
  serviceLoading: PropTypes.bool.isRequired,
  setServiceSearch: PropTypes.func.isRequired,
  selectStatus: PropTypes.func.isRequired,
  noPropertyResults: PropTypes.string,
  title: PropTypes.string.isRequired,
  submitBtnCaption: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
};

AddMasterWorkOrder.defaultProps = {
  noPropertyResults: null,
  selectedProperty: null,
  selectedService: null,
  projectTechnician: null,
  projectManager: null,
  technicians: null,
  managers: null,
  allSuppliers: null,
  serviceTypes: [],
  properties: [],
  errors: {},
  isNever: false,
  repeats: [],
  repeatEvery: null,
  startDate: null,
  endDate: null,
  isEdit: false,
};

export default AddMasterWorkOrder;
